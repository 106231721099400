
<template>
    <div class="box">
        <div class="box-header">
            <h3 class="box-title">Přehled tržeb</h3>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="input-group input-group-sm" style="width: 100%;margin-top: 25px">
                        <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                    </div>
                </div>
                <div class="col-lg-2">
                    <label>Uzavřené od</label>
                    <v-date-picker mode="dateTime" v-model="fromdate" class="flex-grow" is24hr :max-date="todate ? todate : Date.now()">
                        <input slot-scope="{ inputProps, inputEvents, inputValue }" v-bind="inputProps" :value="inputValue" v-on="inputEvents" id="fromdate" class="form-control" autocomplete="off">
                    </v-date-picker>
                </div>
                <div class="col-lg-2">
                    <label>Uzavřené do</label>
                    <v-date-picker mode="dateTime" v-model="todate" class="flex-grow" is24hr :min-date="fromdate" :max-date="Date.now()">
                        <input slot-scope="{ inputProps, inputEvents, inputValue }" v-bind="inputProps" :value="inputValue" v-on="inputEvents" id="todate" class="form-control" autocomplete="off">
                    </v-date-picker>
                </div>
            </div>
            <br>
            <div class="table-responsive no-padding" style="width: auto; overflow: auto">
                <table v-if="getIssues.length" class="table table-hover">
                    <thead>
                    <tr>
                        <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="issue in getIssues" :key="issue.issueid">
                            <td style="cursor: pointer" @click="makeCurrentIssue(issue.issueid)"><a>{{issue.name}}</a></td>
                            <td>{{issue.visible_id}}</td>
                            <td style="cursor: pointer" @click="makeCurrentCar(issue.carid)"><a>{{issue.car_name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentCus(issue.customerid)"><a>{{issue.company_name}}</a></td>
                            <td>{{issue.closed_str}}</td>
                            <td>{{Math.max(0, issue.billing_price_no_dph)}},- Kč</td>
                            <td>{{Math.max(0, issue.billing_price_dph)}},- Kč</td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="callout callout-success">
                    <p><i class="icon fa fa-info"></i> Pro zadané parametry nebyly nalezeny žádné výsledky.</p>                          
                </div>
            </div>
        </div>
        <div v-if="!loaded" class="overlay">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
    </div>
</template>

<script>

const name = 'Sales';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {
        const threshold = parseInt(this.$route.query.threshold) || 30
        const headColumns = [
            { name: 'Název úkonu' },
            { name: 'Číslo úkonu' },
            { name: 'Auto' },
            { name: 'Zákazník' },
            { name: 'Datum uzavření' },
            { name: 'Částka bez DPH' },
            { name: 'Částka s DPH' }
        ]

        var d = new Date();
        d.setHours(23,59,0,0);
        var todate = d.getTime();
        
        d = new Date();
        d.setDate(d.getDate()-threshold);
        d.setHours(0,0,0,0);
        var fromdate = d.getTime();

        return {
            filter: '',
            loaded: false,
            threshold,
            headColumns,
            fromdate,
            todate
        };
    },
    computed: {
        ...mapGetters(["getAllPages", 'allIssues', 'user', 'group']),
        getIssues() {
            var allIssues = this.allIssues
            if (!allIssues.length)
            {
                return []
            }

            const filteredIssues = allIssues.filter(item => {
                return (item.tagid === 3 && item.closed*1000 >= this.fromdate && item.closed*1000 <= this.todate
                && (this.$helpers.filterCheck(this.filter, item.name)
                        || this.$helpers.filterCheck(this.filter, item.car_name)
                        || this.$helpers.filterCheck(this.filter, item.company_name)
                        || this.$helpers.filterCheck(this.filter, item.mnt_name)
                        ));
            });

            return filteredIssues
        }
    },
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "fetchIssues", 'makeCurrentIssue', 'makeCurrentCar', 'makeCurrentCus', 'makeCurrentMnt', 'fetchUser', 'fetchMaintainer']),
        getTime(epochTime) {
            const dt = new Date(epochTime * 1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            const hrs = dt.getHours();
            let min = dt.getMinutes();

            if (min < 10)
            {
                min = '0' + min.toString();
            }

            return `${day}.${mth}.${yrs}  ${hrs}:${min}`;
        }
    },
    created() {
        this.fetchIssues().then(() => { // Loads all issues into this.allIssues
            this.loaded = true;

            this.allIssues.forEach((issue) => {
                if (issue.closed > 0)
                {
                    issue.closed_str = this.getTime(issue.closed);
                }
            });
        });

        const page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(page);
        this.makeActiveTab(page);
    }
}
</script>
